<template>
  <div>
    <div class="container">
      <!-- Title -->
      <div class="page">
        <h4 class="d-flex-center-between m-0">
          <div class="d-flex-center-middle">
            <div class="d-flex" style="margin: 0 auto;">
              <div>
                <div class="exam-title type-title">
                  <div :class="nextTitle ? 'text51' : 'text51'">
                    {{ title }}
                  </div>
                </div>
              </div>
              <div class="exam-title">
                Mocks
              </div>
            </div>
          </div>
          <!-- <div class="role-title">
          </div> -->
          <div class="role-title">&nbsp;for&nbsp;{{ role }}</div>
        </h4>
        <div class="text-center page2-small-title">
          <span v-html="subTitle.smallTitle"></span>
        </div>
        <!-- <h4 style="text-align:center;font-size: 200px;">X</h4> -->
      </div>
    </div>
    <div class="container">
      <!-- Started -->
      <div class="text-center" v-if="subTitle">
        <!-- <div class="page2-title" v-html="subTitle.title"></div> -->
        <router-link
          :to="{ name: mainRouter }"
          style="display:block;color: white !important; text-decoration: none;"
        >
          <div class="page2-button">
            Get Started. It's FREE
            <i class="fas fa-arrow-right"></i>
          </div>
        </router-link>
        <div class="page2-small-title2">
          Free Forever. No Credit Card.
        </div>
      </div>
    </div>
    <!-- Tabs -->
    <div class="tabs" v-if="tabs">
      <div class="container">
        <div class="page">
          <div class="tabs-box-cover">
            <div class="tabs-box">
              <div
                class="tab"
                @click="selectTab(index)"
                :class="index == activeTabIndex ? 'active' : ''"
                v-for="(tab, index) in tabs"
                :key="index"
              >
                <div class="tab-icon" v-show="tab.icon">
                  <div class="tab-icon-img">
                    <i :class="tab.icon"></i>
                  </div>
                </div>
                <div class="tab-title">
                  {{ tab.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content">
            <img
              v-for="(tab, index) in tabs"
              :key="index"
              :class="activeTabIndex === index ? 'fade-in' : ''"
              :style="activeTabIndex === index ? 'height: auto' : 'height:0'"
              :src="tab.img"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container pr-0">
      <!-- Stories -->
      <div class="page tab-details pr-0">
        <div class="details-list">
          <div
            class="mb-4"
            v-for="(tab, index) in mainTabs"
            :key="index"
            @click="activeMainTabIndex = index"
          >
            <div
              :class="
                index == activeMainTabIndex
                  ? 'active mainTab-title'
                  : 'mainTab-title'
              "
            >
              {{ tab.title }}
            </div>
            <div
              class="mainTab-small-title"
              v-show="index == activeMainTabIndex"
            >
              {{ tab.text }}
            </div>
          </div>
        </div>
        <div class="detail-content">
          <div class="detail-img">
            <div class="img-cover">
              <img src="" alt="">
            </div>
            <div class="img-cover-2">
              <img src="" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- Stories -->
      <div class="page stories text-center pb-3" v-if="storiesTitle">
        <div class="stories-title" v-html="storiesTitle.title"></div>
        <div class="stories-small-title" v-html="storiesTitle.smallTitle"></div>
      </div>
    </div>
    <div class="stories-card-box">
      <div class="story-card" v-for="(story, index) in stories" :key="index">
        <div class="story-card-img">
          <img :src="story.cover" alt="" />
        </div>
        <div class="story-card-video">
          <iframe
            :src="`https://www.youtube.com/embed/vk7XzgWNO44`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div class="story-card-footer">
          <div class="story-card-footer-words">"{{ story.words }}"</div>
          <div class="story-card-footer-name">
            {{ story.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="stories-title mt-4  text-center" style="font-size: 1.5rem;">
      {{ storiesTitle.count }}+ {{ role }} Trust XMocks for College Test Prep
    </div>
    <div class="container">
      <div class="page text-center">
        <div class="stories-title mb-4 mt-4">
          The best test prep tool, <br />
          for the best price
        </div>
        <!-- <Plan></Plan> -->
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import Plan from "@/views/plans/Index.vue";
import $ from "jquery";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: [
    "role",
    "subTitle",
    "tabs",
    "mainRouter",
    "mainTabs",
    "stories",
    "storiesTitle"
  ],
  data() {
    return {
      title: "",
      nextTitle: "",
      message: ["SAT", "ACT", "AP", "PSAT", "TOEFL", "IELTS"],
      interval: null,
      // Tabs
      activeTabIndex: 0,
      // mainTabs
      activeMainTabIndex: 0
      // Stories
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.typeAnimation();
    this.selectTab(0);
  },

  methods: {
    selectTab(index) {
      this.activeTabIndex = index;
      this.$nextTick(() => {
        // 假设你想滚动到页面上的某个元素的位置
        var boxCoverPosition = $(".tabs-box-cover").offset();
        var boxPosition = $(".tabs-box").offset();
        var targetPosition = $(".tab.active").offset();
        // console.log($(".tab.active"));
        var right = targetPosition.left - boxPosition.left;
        // console.log(boxCoverPosition.left);
        // console.log(boxPosition.left);
        // console.log(right);
        // 使用animate和scrollTop滚动到指定位置
        $(".tabs-box-cover").animate(
          {
            scrollLeft: right
          },
          "slow"
        );
      });
    },
    typeAnimation() {
      let index = 0;
      let mIndex = 0;
      let time = 50;
      // clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (index < this.message[mIndex].length) {
          if (index == 0) {
            this.nextTitle = false;
          }
          this.title += this.message[mIndex][index++];
        } else {
          index++;
          this.nextTitle = true;
          if (index == this.message[mIndex].length + 50) {
            this.title = "";
            index = 0;
            mIndex++;
            if (mIndex === this.message.length) {
              mIndex = 0;
            }
          }
        }
      }, time); // 每0.1秒添加一个字符
    }
  }
};
</script>
<style scoped>
.fade-out {
  animation: fadeOut 2s linear infinite;
  animation-fill-mode: forwards; /* 保持最后一帧的状态 */
  animation-iteration-count: 1; /* 动画只播放一次 */
}
.fade-in {
  animation: fadeIn 1s linear infinite;
  animation-fill-mode: forwards; /* 保持最后一帧的状态 */
  animation-iteration-count: 1; /* 动画只播放一次 */
}
.logo-icon {
  font-weight: 700;
  display: inline-block;
  font-size: 400px;
  margin: 0;
  text-align: center;
  background: linear-gradient(to right, #0579ff, #ea00ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text51 {
  width: 100%;
  color: var(--themeColor);
  white-space: nowrap;
  /* color: #5ba5fb; */
  background: linear-gradient(
    269deg,
    #fa12e3 1.83%,
    #7612fa 53.68%,
    #12d0fa 106.48%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
<style scoped>
.page {
  width: 100%;
  color: var(--themeColor);
  transition: all 0.3s ease;
}
.tab-details {
  display: flex;
}
.type-title {
  /* width: 12rem; */
  text-align: right;
}

.exam-title,
.role-title,
.exam-title-small {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 4.5rem;
  /* cursor: pointer; */
  user-select: none;
}
.exam-title,
.role-title {
  line-height: 5rem;
  /* margin-bottom: 1rem; */
}
.role-title {
  text-align: center;
}
.exam-title-small {
  font-size: 2rem;
  border: 1px solid var(--themeColor);
  border-radius: 0.6rem;
  padding: 0.2rem;
  width: 5rem;
  text-align: center;
  margin: 0 auto;
  opacity: 0.3;
  transition: box-shadow 0.3s, transform 0.3s;
}
.middle-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 3rem;
  margin: 0 3rem;
}
.d-flex-center-between {
  display: block;
  /* display: -webkit-flex; */
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
}
.d-flex-center-middle {
  display: flex;
  /* display: -webkit-flex; */
  align-items: center;
  justify-content: middle;
}
.page2-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  font-family: "Oswald-Bold";
}
.page2-small-title {
  color: var(--themeColor);
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1rem;
}
.page2-button {
  margin-top: 1rem;
  color: white !important;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--themeColor);
  display: inline-block;
  padding: 1rem 5rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(to right, #5ba5fb, #d90ceb);
  cursor: pointer;
  transition: background-image 0.5s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.page2-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.page2-small-title2 {
  color: var(--themeColor);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1rem;
}
</style>
<style scoped>
.stories-card-box {
  /* width: 1080px; */
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  /* margin-top: 1rem; */
  white-space: nowrap; /* 确保内容不换行 */
}
.story-card {
  display: inline-block;
  width: 30%;
  width: 24rem !important;
  /* height: 40rem !important; */
  margin: 0 0.5rem;
  border-radius: 1rem;
  background: black;
  cursor: pointer;
  position: relative;
}
.story-card:hover .story-card-footer {
  display: none;
}
.story-card-footer {
  color: white;
  display: block;
  position: absolute;
  width: 100%;
  height: 40%;
  border-radius: 0 0 1rem 1rem;
  border-block-start: 1px solid rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.story-card-img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
.story-card:hover .story-card-video iframe {
  display: block;
}
.story-card-video {
  /* width: 100%;
  height: 100%; */
}
.story-card-video iframe {
  border-radius: 1rem;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.story-card-img img {
  display: inline-block;
  width: 100%;
  border-radius: 1rem;
}

.story-card-footer-words {
  white-space: normal;
  margin-block-start: -0.23rem;
  font-size: 1.8rem;
  line-height: 2rem;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}

/* 启用滚动 */
.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch; /* 移动端使用触摸滚动 */
}
</style>
<style scoped>
.tabs {
  position: relative;
}
.tabs::after {
  content: "";
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  bottom: -300px;
  background-image: url("../assets/background-pink.svg");
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-position: center; /* 背景图片居中 */
  z-index: -1;
}
.tabs-box-cover {
  max-width: 100%;
  width: 80rem;
  margin: 1rem auto 0;
  overflow: auto;
  padding-top: 1rem;
  height: 10rem;
}
.tabs-box {
  justify-content: center;
  white-space: nowrap;
  /* position: relative; */
  z-index: -1;
}
.tabs-box-cover {
  position: relative;
}
/* .tabs-box-cover::before,
.tabs-box-cover::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4rem;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 999;
}
.tabs-box-cover::before {
  left: 0;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.1));
}
.tabs-box-cover::after {
  right: 0;
  background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.1));
} */
.tab {
  display: inline-block;
  width: 8rem;
  text-align: center;
  cursor: pointer;
  margin: 0 1rem;
  transition: all 0.3s ease;
  vertical-align: top;
}
.tab-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  background: linear-gradient(130deg, #f8f8fc, #f8f8fc);
  margin: 0 auto;
  transition: all 0.3s ease;
  background: rgba(201, 196, 254, 0.1);
  filter: drop-shadow(0 5px 20px rgba(127, 118, 179, 0.1));
}
.tab-icon-img {
  height: 4rem;
  font-size: 1.5rem;
  /* line-height: 4rem; */
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active .tab-icon {
  /* width: 4.5rem;
  height: 4.5rem;
  border-radius: 1.2rem; */
  background: var(--themeColor);
  color: white;
  box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.3);
  transform: translateY(-0.5rem) scale(1.25);
}
.active .tab-icon-img {
  line-height: 4.5rem;
  font-size: 1.6rem;
}
.tab:hover .tab-icon {
  /* box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.3); */
}
.tab:hover {
  transform: translateY(-0.5rem);
}
.tab.active:hover {
  transform: translateY(0);
}
.active .tab-title {
  font-weight: 700;
}
.tab-title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1rem;
  margin-top: 0.5rem;
  white-space: normal;
  font-family: "Oswald-Bold";
}
.tab-content {
  border-radius: 2px;
  /* padding: 1rem; */
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #ceceea;
  border-radius: 1rem;
  /* background: linear-gradient(#fff, hsla(0, 0%, 100%, 0)) padding-box,
    linear-gradient(90deg, #45c4f9, #ff0be5) border-box; */
}
.tab-content img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 2px;
  transition: all 0.2s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
</style>
<!-- Stories -->
<style scoped>
.stories-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  /* font-style: italic; */
  font-family: "Oswald-Bold";
}
.stories-small-title {
  color: var(--themeColor);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1rem;
}
</style>
<!-- mainTabs -->
<style scoped>
.details-list {
  margin-bottom: 1rem;
  min-width: 30rem;
  max-width: 40%;
  padding-right: 5rem
}
.detail-content {
  margin: 0;
  position: relative;
  height: 630px;
  width: 860px;
  overflow: hidden;
}
.detail-img {
  position: absolute;
  left: 0;
  top: 0;
  inset-inline-end: -385px;
  inset-block-start: 0;
  inset-block-end: 0;
  /* overflow: hidden; */
  width: 840px;
  height: 562px;
  border-radius: 14px;
  background: linear-gradient(247deg, #fa12e3 17.39%, #7612fa 64.22%, #12d0fa 97.73%);
  box-shadow: 0 3px 20px 0 rgba(67, 46, 134, .1);
  will-change: transform;
}
.detail-img .img-cover {
  position: relative;
  opacity: 1;
  inset-inline-start: 20px;
  inset-block-start: 20px;
  inline-size: 864px;
  width: 840px;
  height: 562px;
  border: 1px solid transparent;
  border-radius: 14px;
  will-change: transform;
  background-color: #ccc;
  z-index: 999;
  border: 1px solid #ceceea;
}
.img-cover-2 {
  position: absolute;
  opacity: 1;
  left: -94px;
  top: 66px;
  border-radius: 14px;
  will-change: transform;
  background-color: white;
  z-index: 999;
  width: 352px;
  height: 359px;
  border: 1px solid #ceceea;
  display: none;
}
.mainTab-title {
  color: var(--themeColor);
  font-weight: 700;
  font-size: 2.5rem;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Oswald-Bold";
}
.mainTab-title:hover {
  transform: translateY(-0.5rem);
  background: linear-gradient(
    269deg,
    #fa12e3 1.83%,
    #7612fa 53.68%,
    #12d0fa 106.48%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.active.mainTab-title {
  background: linear-gradient(
    269deg,
    #fa12e3 2.83%,
    #7612fa 53.68%,
    #12d0fa 106.48%
  );
  background-clip: text;
  opacity: 1;
  -webkit-text-fill-color: transparent;
}
.active.mainTab-title:hover {
  transform: translateY(0);
}
.mainTab-small-title {
  color: var(--themeColor);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.5rem;
  transition: all 0.3s ease;
  text-align: left;
}
.detail-content {
  /* background: #ccc;
  border: 1px solid var(--themeColor);
  border-radius: 1rem;
  height: 30rem;
  width: 100%;
  margin: 0 auto;
  margin-left: 2rem;
  border: 0.2rem solid transparent;
  border-radius: 1rem;
  background: linear-gradient(#fff, hsla(0, 0%, 100%, 0)) padding-box,
    linear-gradient(90deg, #45c4f9, #ff0be5) border-box; */
}
</style>
<style scoped>
@media screen and (min-width: 1080px) {
  .page {
    padding: 3rem 4rem;
    border: none;
  }
}
@media screen and (max-width: 1080px) {
  .page {
    padding: 2rem 1rem;
    /* border-top: 1px solid #efefef; */
  }
}
@media screen and (max-width: 768px) {
  .page {
    padding: 1rem 0;
  }
  .d-flex-center-between,
  .d-flex-center-middle {
    display: block;
    text-align: center;
  }

  .d-flex-center-middle {
    display: flex;
    text-align: center;
  }
  .type-title {
    height: 5rem;
  }
  .tab-details {
    display: block;
    text-align: center;
  }
  .details-list {
    margin-bottom: 1rem;
    padding: 0;
  }
  .detail-content {
    width: 100%;
    margin: 0;
  }
  .stories-small-title {
    text-align: left;
  }
}
</style>
